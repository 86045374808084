// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/opt/build/repo/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-careers-apply-js": () => import("/opt/build/repo/src/pages/careers/apply.js" /* webpackChunkName: "component---src-pages-careers-apply-js" */),
  "component---src-pages-careers-index-js": () => import("/opt/build/repo/src/pages/careers/index.js" /* webpackChunkName: "component---src-pages-careers-index-js" */),
  "component---src-pages-careers-thank-you-js": () => import("/opt/build/repo/src/pages/careers/thank-you.js" /* webpackChunkName: "component---src-pages-careers-thank-you-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-quote-index-js": () => import("/opt/build/repo/src/pages/quote/index.js" /* webpackChunkName: "component---src-pages-quote-index-js" */),
  "component---src-pages-quote-thank-you-js": () => import("/opt/build/repo/src/pages/quote/thank-you.js" /* webpackChunkName: "component---src-pages-quote-thank-you-js" */),
  "component---src-pages-services-commercial-landscaping-js": () => import("/opt/build/repo/src/pages/services/commercial-landscaping.js" /* webpackChunkName: "component---src-pages-services-commercial-landscaping-js" */),
  "component---src-pages-services-erosion-control-js": () => import("/opt/build/repo/src/pages/services/erosion-control.js" /* webpackChunkName: "component---src-pages-services-erosion-control-js" */),
  "component---src-pages-services-fertilization-js": () => import("/opt/build/repo/src/pages/services/fertilization.js" /* webpackChunkName: "component---src-pages-services-fertilization-js" */),
  "component---src-pages-services-residential-landscaping-js": () => import("/opt/build/repo/src/pages/services/residential-landscaping.js" /* webpackChunkName: "component---src-pages-services-residential-landscaping-js" */),
  "component---src-pages-services-snow-removal-js": () => import("/opt/build/repo/src/pages/services/snow-removal.js" /* webpackChunkName: "component---src-pages-services-snow-removal-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

